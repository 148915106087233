<template>
  <b-card title="Ubah Password" style="max-width: 500px;margin: auto;">
    <b-form @submit.prevent="onSubmit">
        <b-form-group
            id="input-group-1"
            label="Password Sekarang:"
            label-for="input-1"
        >
            <b-form-input
            id="input-1"
            v-model="form.old_password"
            type="password"
            placeholder="Ketikan password sekarang"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group
            id="input-group-2"
            label="Password Baru:"
            label-for="input-2"
        >
            <b-form-input
            id="input-2"
            v-model="form.password"
            type="password"
            placeholder="Ketikan password baru"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group
            id="input-group-3"
            label="Ulangi Password Baru:"
            label-for="input-3"
        >
            <b-form-input
            id="input-3"
            v-model="form.password_confirmation"
            type="password"
            placeholder="Ketikan ulang password baru"
            required
            ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </b-card>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          old_password: '',
          password: '',
          password_confirmation: ''
        }
      }
    },
    methods: {
      resetForm () {
          this.form.old_password = ''
          this.form.password = ''
          this.form.password_confirmation = ''
      },
      onSubmit() {
        this.$http.patch(`${process.env.VUE_APP_API_URL}me/password`, this.form).then(() => {
            this.resetForm()
            this.$store.dispatch('notification/success', 'Password berhasil diubah')
        }).catch(error => {
            this.$store.dispatch('notification/error', error)
        })
      }
    }
  }
</script>